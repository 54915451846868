<template>
  <div id="app">
    <nav class="pc-nav">
      <div class="logo">
        <img src="./assets/images/logo.png">
        <router-link to="/">湖北创业工场</router-link>
      </div>
      <div class="menu">
        <router-link to="/about">关于我们</router-link>
        <a href="javascript:;" @click="btn_to('Service')">服务</a>
        <!-- <a href="javascript:;">联系我们</a> -->
      </div>
    </nav>
    <nav class="m-nav">
      <div class="logo">
        <img src="./assets/images/logo.png">
        <router-link to="/">湖北创业工场</router-link>
      </div>
      <div class="menu">
        <a href="javascript:;" @click="btn_menuList"><i class="bi bi-list"></i></a>
      </div>
    </nav>
    <router-view />
    <footer>
      <div class="bottom-menu">
        <div class="column column1">
          <div class="row1">
            <img src="./assets/images/logo.png">
            <div class="row2">湖北创业工场</div>
          </div>
        </div>
        <div class="column">
          <div class="row1">创业</div>
          <a href="javascript:;" @click="btn_url('about')">关于我们</a>
        </div>
        <div class="column">
          <div class="row1">产品</div>
          <a target="_blank" href="https://www.future.top">未来全屏通CR眼镜</a>
          <a target="_blank" href="https://www.whguojiu.com/">中国灸</a>
          <a target="_blank" href="http://www.whgjdjk.com/">大健康</a>
        </div>
        <div class="column column4">
          <div class="row1">友情链接</div>
          <div class="row2">
            <a target="_blank" href="https://36kr.com/">36氪</a>
            <a target="_blank" href="https://www.krspace.com/">氪空间</a>
            <a target="_blank" href="https://www.aliyun.com/">阿里云创新中心</a>
            <a target="_blank" href="https://meeting.tencent.com/">视频会议</a>
            <a target="_blank" href="http://www.cnmrn.com.cn/">中国市场调查网</a>
            <a target="_blank" href="https://www.cnnic.cn/">互联网数据</a>
            <a target="_blank" href="https://www.chinacpda.org/">行业报告</a>
          </div>
        </div>
      </div>
      <div class="text1">
        <span>Copyright 2020-2023 Future Fullscreen Intelligent Technology Group Co., Ltd.鄂ICP备2023002274号-1</span>
      </div>
      <div class="text1">
        <img src="./assets/images/police-badge.png"><a href="javascript:;">鄂公网安备42018502007119</a>
      </div>
    </footer>
    <div class="modal-back" v-show="isShowMenu"></div>
    <div class="modal-menu" v-show="isShowMenu">
      <a href="javascript:;" @click="btn_url('about')">关于我们</a>
      <a href="javascript:;" @click="btn_to('Service')">服务</a>
      <!-- <a href="javascript:;">联系我们</a> -->
      <a href="javascript:;" @click="btn_url('cr')">未来全屏通CR眼镜</a>
      <a href="javascript:;" @click="btn_url('eye')">中国护眼灸</a>
      <a href="javascript:;" @click="btn_url('stock')">科技+A股</a>
    </div>
    <div class="model-close" @click="btn_closeMenu" v-show="isShowMenu">
      <i class="bi bi-x-lg"></i>
    </div>
  </div>
</template>

<style lang="less">
.trunk {
  max-width: 100%;
  min-height: 100vh;
  margin-top: 60px;
  // padding-bottom: 50px;
  position: relative;
}

/* 宽度 */
::-webkit-scrollbar {
  width: 6px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#app {

  // background-color: #f5f5f5;
  a {
    text-decoration: none;
  }
}

:root {
  --fontColor: #D3201A;
  --fontColorA: #FAD98D;
  --fontColorB: #353A40;
  --fontColorC: #6A6E7B;
  --fontColorD: #F0464C;
  --fontColorE:#2B2BFF;
}


nav {
  a {
    color: #5F687B;
    font-size: 18px;
    font-weight: bold;
  }
}

.pc-nav,
.m-nav {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #ffffff;

  .logo {
    // background-color: salmon;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;

    img {
      width: 35px;
      height: 35px;
    }
  }
}

.pc-nav {
  padding: 0 100px;

  .menu {
    display: flex;
    justify-content: right;
    column-gap: 50px;
  }
}

.m-nav {
  padding: 0 20px;

  .menu {
    i {
      font-size: 24px;
    }
  }
}



footer {
  width: 100%;
  // height: 300px;
  padding: 10px 0 20px 0;
  background-color: #292F49;
  font-size: 14px;

  .bottom-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 5px;
    border-bottom: 1px solid #5d6174;
    // background-color: salmon;
    margin-bottom: 20px;

    .column {
      color: #ffffff;
      // background-color: salmon;
      min-height: 110px;
      // border: 1px solid red;

      .row1 {
        // background-color: sandybrown;
        height: 40px;
        line-height: 40px;
        font-weight: bold;
      }

      a {
        color: #878DA9;
        display: block;
        margin-bottom: 5px;
        // border: 1px solid seagreen;
      }
    }


    .column1 {
      margin-right: 30px;

      .row1 {
        display: flex;
        justify-content: left;
        align-items: center;
        font-size: 18px;
        column-gap: 10px;
        height: 80px;

        img {
          width: 25px;
          height: 25px;
        }
      }
    }

    .column4 {
      width: 300px;

      .row2 {
        display: flex;
        justify-content: left;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 10px;
      }
    }
  }

  .text1 {
    color: #ffffff;
    // background-color: salmon;
    text-align: center;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 20px;
    color: #878DA9;
    width: 90%;
    margin: 10px auto;

    img {
      width: auto;
      height: 15px;
      margin-right: 5px;
    }

    a {
      color: #878DA9;
    }
  }

}

.modal-back {
  width: 100%;
  height: 100vh;
  background: rgba(73, 80, 94, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.modal-menu {
  width: 90vw;
  height: 80vh;
  background-color: #ffffff;
  position: fixed;
  top: 7vh;
  left: 5vw;
  z-index: 100;
  border-radius: 5px;

  a {
    width: 90%;
    display: block;
    margin: auto;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #f5f5f5;
    color: #5f687b;
    font-size: 15px;
  }
}

.model-close {
  position: fixed;
  z-index: 110;
  top: 2vh;
  right: 5vw;
  color: #ffffff;

  i {
    font-size: 24px;
  }
}

.child-modal-back {
    width: 100%;
    height: 100vh;
    background: rgba(73, 80, 94, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.child-modal-content {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img{
        width: 300px;
        height: auto;
        max-width: 90vw;
    }
}

.pro-top-title {
    max-width: 90vw;
    margin: auto;
    text-align: center;

    .title1 {
        font-size: 36px;
        font-weight: bold;
        // margin-top: 50px;
        margin-bottom: 20px;
    }

    .title2 {
        font-size: 22px;
    }
}

.apply-panel {
  max-width: 90vw;
  width: 480px;
  // height: 500px;
  background-color: #ffffff;
  border-radius: 10px;
  text-align: center;
  padding: 30px;

  .form-row {
    // border: 1px solid sandybrown;
    width: 100%;
    font-size: 24px;
  }

  .fr1 {
    color: var(--fontColorB);
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
  }

  .fr2 {
    // background-color: #F3F3F3;
    // height: 60px;
    // border-radius: 100px;
    margin-bottom: 20px;

    .form-control {
      width: 100%;
      height: 60px;
      border-radius: 100px;
      background-color: #F3F3F3;
      border: none;
      font-size: 22px;
      padding: 0 30px;
    }

    .input-group {
      .form-control {
        width: 60%;
      }

      .btn-sms {
        width: 40%;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        background-color: #f9d1c6;
        font-size: 22px;
        color: #ffffff;
      }
    }

  }

  .fr3 {
    display: flex;
    color: #707070;
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin-bottom: 20px;

    .form-check-input {
      margin-right: 10px;
    }

    a {
      color: #F0464C;
    }


  }

  .fr4 {
    margin-bottom: 30px;

    button {
      width: 100%;
      height: 60px;
      border-radius: 100px;
      background-color: #F8DCD4;
      color: #ffffff;
    }

    :hover {
      background-color: #f9d1c6;
    }

  }

  .fr5 {
    display: flex;
    justify-content: center;
    font-size: 18px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 40px;
    max-width: 100%;
    column-gap: 50px;

    .item-panel {
      // background-color: salmon;
      margin-bottom: 20px;
      display: flex;
      justify-content: left;
      align-items: center;
      column-gap: 10px;
      white-space: nowrap;
      animation: gdt 15s linear infinite;

      img {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        // background-color: salmon;
      }

      .open-msg {
        color: var(--fontColorD);
        margin-left: 10px;
      }
    }
  }
}

@keyframes gdt {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(-400%);
    }
}

@media (max-width:600px) {
  .pc-nav {
    display: none;
  }

  .apply-panel{
    .fr1 {
                font-size: 16px;
                margin-bottom: 20px;
            }

            .fr2 {
                margin-bottom: 10px;

                .form-control {
                    font-size: 14px;
                }

                .input-group {
                    .btn-sms {
                        font-size: 14px;
                    }
                }
            }

            .fr3 {
                font-size: 12px;
                margin-bottom: 10px;
            }

            .fr4 {
                margin-bottom: 20px;
            }

            .fr5 {
                font-size: 14px;
            }
  }

  footer {
    .bottom-menu {
      width: 90%;
      margin: auto;

      .column {
        width: 100%;
        height: auto;
        min-height: auto;

        a {
          margin-bottom: 15px;
        }
      }

      .column4 {
        .row2 {
          column-gap: 30px;
        }
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  .m-nav {
    display: none;
  }
}

@media (min-width: 1200px) {
  .m-nav {
    display: none;
  }
}
</style>

<script>
export default {
  data() {
    return {
      isShowMenu: false,
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    btn_menuList() {
      this.isShowMenu = true;
    },
    btn_closeMenu() {
      this.isShowMenu = false;
    },
    btn_to(id) {
      this.$router.push({ name: 'home' }).catch(ca => ca);
      this.$nextTick(() => {
        var anchor = document.getElementById(id);
        // console.log(anchor.offsetTop);
        window.scrollTo(0, anchor.offsetTop - 70);
        this.isShowMenu = false;
      })
    },
    btn_url(router) {
      this.$router.push({ name: router }).catch(ca => ca);
      this.$nextTick(() => {
        this.isShowMenu = false;
      })
    },
  }
}
</script>
