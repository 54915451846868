import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/agreement.vue')
  },
  {
    path: '/projects/stock',
    name: 'stock',
    component: () => import('../views/projects/stock.vue')
  },
  {
    path: '/projects/cr',
    name: 'cr',
    component: () => import('../views/projects/cr.vue')
  },
  {
    path: '/projects/eye',
    name: 'eye',
    component: () => import('../views/projects/eye.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test.vue')
  },
]

const router = new VueRouter({
  routes
})

// 跳转后返回顶部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

export default router
